body{
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
}

html{
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
}
